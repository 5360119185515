module.exports = {
  en: {
    is_default: true,
    path: 'en',
    display_name: 'English',
  },
  es: {
    path: 'es',
    display_name: 'Español',
  },
  pt: {
    path: 'pt',
    display_name: 'Português',
  },
};
