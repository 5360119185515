import i18n from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import languageConfig from '../../lang-config';

i18n
  .use(new ICU())
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',

    // To investigate react-i18next translation issues enable this
    debug: false,

    interpolation: {
      escapeValue: false,
    },

    react: {
      wait: true,
      useSuspense: false,
    },
  });

i18n.languages = Object.keys(languageConfig);

export const t = (string, values) => i18n.t(string, { defaultValue: string, ...values });
export default i18n;
